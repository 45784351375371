import { memo } from 'react';

import { ScrollView, Stack } from '@arrived/bricks';
import { RefreshControl } from '@arrived/bricks-common';
import { useOfferingFilters } from '@arrived/contexts';

import { EmptyState } from '../EmptyState';
import {
  useDisplayEmptyState,
  useDisplayLoading,
  useInvestContext,
  useInvestContextOfferingSearchQuery,
} from '../InvestContext';
import { Loading as DefaultLoading } from '../Loading';

import { GridProps } from './GridProps';

export const Grid = memo(({ children, Card, Empty = EmptyState, Loading = DefaultLoading, ...rest }: GridProps) => {
  const {
    paginationProps,
    useDisclosureProps: { onOpen },
  } = useInvestContext();

  const { offeringFilter } = useOfferingFilters();

  const offeringState = useInvestContextOfferingSearchQuery({ offeringFilter, paginationProps });
  const displayEmptyState = useDisplayEmptyState(offeringState);
  const displayLoading = useDisplayLoading(offeringState);

  return (
    <ScrollView
      refreshControl={<RefreshControl onRefresh={offeringState.refetch} />}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <Stack
        p="$4"
        gap="$4"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
          gridAutoRows: 'max-content',
        }}
        {...rest}
      >
        {displayEmptyState || displayLoading ? (
          <Stack display="flex" alignItems="center" justifyContent="center" style={{ gridColumn: 'span 12' }}>
            {/* These are mutually exclusive states based on the hook construction so we can just render them both this way */}
            {displayLoading && <Loading />}
            {displayEmptyState && <Empty maxWidth={358} openFilterSheet={onOpen} />}
          </Stack>
        ) : (
          offeringState.data?.data.map((offering) => (
            <Card
              aspectRatio="1/1"
              width="100%"
              maxHeight={400}
              offering={offering}
              key={offering.id}
              style={{
                gridColumn: 'span 12',
              }}
              // @ts-expect-error style works on web
              $gtXxs={{ maxHeight: 'unset', style: { gridColumn: 'span 6' } }}
              // @ts-expect-error style works on web
              $gtSm={{ style: { gridColumn: 'span 4' } }}
              // @ts-expect-error style works on web
              $gtLg={{ style: { gridColumn: 'span 3' } }}
            />
          ))
        )}
        {children}
      </Stack>
    </ScrollView>
  );
});
