import { useCallback, useEffect, useState } from 'react';
import { LayoutChangeEvent } from 'react-native';

import { FormattedMessage, useIntl } from 'react-intl';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';

import { Button, Count, FilterIcon, Stack, StackProps, isWeb, useMedia } from '@arrived/bricks';
import { useOfferingFilters } from '@arrived/contexts';

import { useInvestContext } from '../InvestContext';

const AnimatedCount = Animated.createAnimatedComponent(Count);

export const FilterButton = (props: StackProps) => {
  const media = useMedia();
  const intl = useIntl();

  const [filterCount, setFilterCount] = useState(0);
  const [countWidth, setCountWidth] = useState<number>();
  const [countHeight, setCountHeight] = useState<number>();

  const { getFilterCount } = useOfferingFilters();
  const {
    useDisclosureProps: { getButtonProps },
  } = useInvestContext();

  useEffect(() => {
    let cancel = false;

    getFilterCount().then((count) => {
      if (!cancel) {
        setFilterCount(count);
      }
    });

    return () => {
      cancel = true;
    };
  }, [getFilterCount]);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    if (isWeb) {
      return;
    }
    setCountWidth(event.nativeEvent.layout.width);
    setCountHeight(event.nativeEvent.layout.height);
  }, []);

  return (
    <Stack ml="auto" position="relative" {...props}>
      <Button
        data-e2e="invest-filter-sheet-open"
        iconPosition="before"
        Icon={FilterIcon}
        variant="ghost"
        aria-label={intl.formatMessage({ id: 'common.filter' })}
        {...getButtonProps()}
      >
        {media.gtXs && <FormattedMessage id="common.filter" />}
      </Button>
      {/* For some reason, animate presence doesn't work here */}
      {filterCount > 0 && (
        <AnimatedCount
          key={`${filterCount > 0}`}
          entering={FadeIn.duration(200)}
          exiting={FadeOut.duration(200)}
          variant="primary"
          onLayout={onLayout}
          position="absolute"
          top={0}
          right={0}
          transform="translate(50%, -50%)"
          $platform-native={{
            transform: [{ translateX: (countWidth ?? 0) * 0.5 }, { translateY: (countHeight ?? 0) * -0.3 }],
          }}
        >
          {filterCount}
        </AnimatedCount>
      )}
    </Stack>
  );
};
