import { FC, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { getOfferingPhotos } from '@arrived/api_v2';
import { useOfferingFilters } from '@arrived/contexts';
import { offeringPhotosKeyFn, useOfferingSearchQuery } from '@arrived/queries';

import { useInvestContext, useInvestContextOfferingSearchQuery } from './InvestContext';

export interface HooksProps {
  children: FC<HooksChildProps>;
}

interface HooksChildProps {
  offeringState: ReturnType<typeof useOfferingSearchQuery>;
}

export const Hooks = ({ children: Children }: HooksProps) => {
  const { paginationProps } = useInvestContext();
  const { offeringFilter } = useOfferingFilters();

  const offeringState = useInvestContextOfferingSearchQuery({ offeringFilter, paginationProps });

  return <Children offeringState={offeringState} />;
};

const PreloadOfferingPhotos = ({ offeringState }: HooksChildProps) => {
  const queryClient = useQueryClient();

  useEffect(
    () =>
      offeringState.data?.data.forEach(({ cid }) => {
        if (cid) {
          queryClient.prefetchQuery({
            queryKey: offeringPhotosKeyFn(cid, { includeUrls: true }),
            queryFn: () => getOfferingPhotos(cid, { includeUrls: true }),
          });
        }
      }),
    [offeringState.data?.data],
  );

  return null;
};

Hooks.PreloadOfferingPhotos = PreloadOfferingPhotos;
