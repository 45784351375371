import { useMemo } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';
import Animated from 'react-native-reanimated';

import { Ratio, Stack, StackProps, UtilityText, UtilityTextProps, useProgressBarAnimation } from '@arrived/bricks';
import { OfferingTransactState, getPercentFunded } from '@arrived/common';
import { useOfferingTransactState } from '@arrived/hooks';
import { Offering, OfferingType } from '@arrived/models';

const AnimatedRatioFill = Animated.createAnimatedComponent(Ratio.Fill);

export type InvestCardProgressProps = StackProps & { offering: Offering; UtilityTextProps?: UtilityTextProps };

export const InvestCardProgress = ({ offering, UtilityTextProps, ...rest }: InvestCardProgressProps) => {
  const offeringTransactState = useOfferingTransactState(offering);
  const show = useMemo(() => offering.type !== OfferingType.FUND, [offering.type]);

  const fg = useMemo(() => {
    switch (offeringTransactState) {
      case OfferingTransactState.CLOSED:
        return '$onSurface.positive.defaultAlt';
      case OfferingTransactState.IN_PROGRESS:
        return '$interactive.primary.rested';
      default:
        return;
    }
  }, [offeringTransactState]);

  const fundedPercent = useMemo(
    () => (offeringTransactState === OfferingTransactState.PRE ? 0 : getPercentFunded(offering)),
    [offeringTransactState, offering],
  );

  const [progressAnimationStyles, setProgressContainerWidth] = useProgressBarAnimation(fundedPercent);

  return (
    <Stack alignItems="center" row gap="$2" minHeight={12} {...rest}>
      {show && (
        <>
          <Ratio flex={1} h={8}>
            <Ratio.Inner h="100%" m={0} bg="$onSurface.primary.outline" onLayout={setProgressContainerWidth}>
              <AnimatedRatioFill h="100%" bg={fg} style={progressAnimationStyles} />
            </Ratio.Inner>
          </Ratio>
          <UtilityText
            token="utility.label.xxsmall"
            color="$interactive.neutral.restedInverted"
            textTransform="uppercase"
            {...UtilityTextProps}
          >
            {offeringTransactState === OfferingTransactState.CLOSED ? (
              <FormattedMessage id="invest.funded" />
            ) : (
              <FormattedNumber
                value={fundedPercent / 100}
                style="percent"
                maximumFractionDigits={0}
                minimumFractionDigits={0}
              />
            )}
          </UtilityText>
        </>
      )}
    </Stack>
  );
};
