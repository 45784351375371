import { Stack, StackProps } from '@arrived/bricks';

import { AssetTypeFilters, FilterButton } from './filter';

export const Header = (props: StackProps) => {
  return (
    <Stack
      data-e2e="invest-page-header"
      bg="$onSurface.neutral.defaultInverted"
      gap="$1"
      flexDirection="row"
      width="100%"
      px="$4"
      py="$3"
      centered
      {...props}
    >
      <Stack flex={1}>
        <AssetTypeFilters />
      </Stack>
      <FilterButton ml="auto" />
    </Stack>
  );
};
