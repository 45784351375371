import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function LockInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 20 20" {...rest}>
      <Path d="M17.5 9.16667H2.5V19.1667H17.5V9.16667Z" stroke={color} strokeWidth={1.25} />
      <Path
        d="M5.83331 9.16667V5C5.83331 2.69882 7.6988 0.833334 9.99998 0.833334C12.3011 0.833334 14.1666 2.69882 14.1666 5V9.16667"
        stroke={color}
        strokeWidth={1.25}
      />
      <Path d="M10 12.5V15.8333" stroke={color} strokeWidth={1.25} />
    </Svg>
  );
}

export const LockIcon = memo<IconProps>(withIconTheme(LockInternal));
