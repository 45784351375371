import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function HourglassInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 20 20" {...rest}>
      <Path
        d="M2.5 1.667h15m-15 16.666h15M5 6.667v-5h10v5L11.667 10 15 13.333v5H5v-5L8.333 10 5 6.667ZM8.333 10h3.334"
        stroke={color}
        strokeWidth={1.25}
      />
    </Svg>
  );
}

export const HourglassIcon = memo<IconProps>(withIconTheme(HourglassInternal));
