import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function CircleClockInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        d="M1.66666 10.0001C1.66666 12.2102 2.54464 14.3298 4.10744 15.8926C5.67024 17.4554 7.78986 18.3334 10 18.3334C12.2101 18.3334 14.3298 17.4554 15.8926 15.8926C17.4554 14.3298 18.3333 12.2102 18.3333 10.0001C18.3333 7.78994 17.4554 5.67033 15.8926 4.10752C14.3298 2.54472 12.2101 1.66675 10 1.66675C7.78986 1.66675 5.67024 2.54472 4.10744 4.10752C2.54464 5.67033 1.66666 7.78994 1.66666 10.0001Z"
        stroke={color}
        strokeWidth={1.25}
      />
      <Path d="M10 5V10H6.66666" stroke={color} strokeWidth={1.25} />
    </Svg>
  );
}

export const CircleClockIcon = memo<IconProps>(withIconTheme(CircleClockInternal));
