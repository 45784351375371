import * as React from 'react';
import { memo } from 'react';

import { ClipPath, Defs, G, Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function NewInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 24 24" {...rest}>
      <G clipPath="url(#prefix__clip0_364_175)" stroke={color} strokeWidth={1.5}>
        <Path d="M6 3L4.83 4.83 3 6l1.83 1.17L6 9l1.17-1.83L9 6 7.17 4.83 6 3zM6.605 13.814l-1.406 2.199L3 17.418l2.199 1.406 1.406 2.199 1.405-2.199 2.2-1.406-2.2-1.405-1.405-2.199zM15.654 4.987L13.78 8.56l-3.57 1.87 3.57 1.872 1.874 3.573 1.873-3.573 3.571-1.87-3.57-1.872-1.874-3.573z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0_364_175">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export const NewIcon = memo<IconProps>(withIconTheme(NewInternal));
