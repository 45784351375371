import { FC, useMemo } from 'react';

import { MessageDescriptor } from 'react-intl';

import { differenceInDays, parseISO } from 'date-fns';

import {
  CheckmarkCircleIcon,
  CircleClockIcon,
  HourglassIcon,
  IconProps,
  NewIcon,
  PauseIcon,
  TagPropsNonPressable,
} from '@arrived/bricks';
import { OfferingDisplayState, getOfferingHasNewProperties } from '@arrived/common';
import { useInvestmentProductType, useOfferingDisplayState } from '@arrived/hooks';
import { InvestmentProductType, OfferingId } from '@arrived/models';
import { useOfferingQuery } from '@arrived/queries';

export interface InvestCardTagContent {
  message: MessageDescriptor['id'];
  Icon: FC<IconProps>;
  variant?: TagPropsNonPressable['variant'];
}

export function useInvestCardTagContent(offeringId: OfferingId): InvestCardTagContent | undefined {
  const offeringDisplayState = useOfferingDisplayState(offeringId);
  const offeringState = useOfferingQuery(offeringId);
  const investmentProductType = useInvestmentProductType(offeringState.data);

  const showNewPropertiesFlag = useMemo(
    () =>
      offeringState.isSuccess && investmentProductType === InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND
        ? getOfferingHasNewProperties(offeringState.data)
        : false,
    [investmentProductType, offeringState.data, offeringState.isSuccess],
  );

  const shouldShowSelloutDate = useMemo(
    () =>
      offeringState.data?.lastTransactionDate &&
      differenceInDays(new Date(), parseISO(offeringState.data?.lastTransactionDate)) < 14,
    [offeringState.data?.lastTransactionDate],
  );

  return useMemo(() => {
    if (offeringDisplayState === OfferingDisplayState.AVAILABLE && showNewPropertiesFlag) {
      return {
        message: 'common.new-properties',
        Icon: NewIcon,
        variant: 'white',
      };
    }

    switch (offeringDisplayState) {
      case OfferingDisplayState.CLOSE:
        return {
          message: 'common.almost-gone',
          Icon: HourglassIcon,
          variant: 'white',
        };
      case OfferingDisplayState.NEW:
        return {
          message: 'common.new',
          Icon: NewIcon,
          variant: 'white',
        };
      case OfferingDisplayState.SOLD_OUT:
        return shouldShowSelloutDate
          ? {
              message: 'common.fully.funded.time',
              Icon: CheckmarkCircleIcon,
            }
          : undefined;
      case OfferingDisplayState.COMING_SOON:
        return {
          message: offeringState.isSuccess && offeringState.data.ipoDate ? 'common.available-on' : 'common.coming-soon',
          Icon: CircleClockIcon,
        };
      case OfferingDisplayState.PAUSED:
        return {
          message: 'common.paused',
          Icon: PauseIcon,
        };
    }
  }, [offeringState.isSuccess, offeringState.data?.ipoDate, offeringDisplayState, shouldShowSelloutDate]);
}
