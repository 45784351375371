import { Fragment, useEffect, useMemo } from 'react';
import { LayoutAnimation } from 'react-native';

import { useIntl } from 'react-intl';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BodyText,
  Button,
  Stack,
  StackProps,
  composeEventHandlers,
} from '@arrived/bricks';
import { useOfferingFilters } from '@arrived/contexts';

import {
  useDisplayEmptyState,
  useDisplayLoading,
  useInvestContext,
  useInvestContextOfferingSearchQuery,
} from './InvestContext';

type FooterProps = StackProps & {
  condensed?: boolean;
  onPagePressed?: () => void;
};

export const Footer = ({ condensed, onPagePressed, ...rest }: FooterProps) => {
  const { paginationProps } = useInvestContext();
  const { offeringFilter } = useOfferingFilters();
  const intl = useIntl();

  const { canNextPage, canPreviousPage, nextPage, page, previousPage, setPage, totalPages } = paginationProps;

  const offeringState = useInvestContextOfferingSearchQuery({ offeringFilter, paginationProps });

  const pageGroups = useMemo(() => {
    const pageGroups = [] as number[][];
    if (condensed) {
      if (page === 1) {
        pageGroups.push(Array.from({ length: Math.min(3, totalPages) }, (_, idx) => idx + 1));
      } else if (page === totalPages) {
        pageGroups.push(Array.from({ length: Math.min(3, totalPages) }, (_, idx) => idx + Math.max(1, totalPages - 2)));
      } else {
        pageGroups.push(Array.from({ length: 3 }, (_, idx) => idx + page - 1));
      }
    } else if (totalPages <= 5) {
      pageGroups.push(Array.from({ length: totalPages }, (_, idx) => idx + 1));
    } else {
      pageGroups.push([1]);
      pageGroups.push(Array.from({ length: 3 }, (_, idx) => idx + Math.max(3, Math.min(totalPages - 2, page)) - 1));
      pageGroups.push([totalPages]);
    }
    return pageGroups;
  }, [condensed, page, totalPages]);

  const displayEmptyState = useDisplayEmptyState(offeringState);
  const displayLoading = useDisplayLoading(offeringState);

  const hideFooter = useMemo(
    () => displayEmptyState || displayLoading || totalPages <= 1,
    [displayEmptyState, displayLoading, totalPages],
  );

  useEffect(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [hideFooter]);

  // To make sure that this component can always be mounted, wrap in a fragment
  return (
    <>
      {!hideFooter && (
        <Stack alignItems="center" justifyContent="center" flexDirection="row" gap="$2" {...rest}>
          <Button
            variant="ghost"
            disabled={!canPreviousPage()}
            Icon={ArrowLeftIcon}
            onPress={composeEventHandlers(previousPage, onPagePressed)}
            aria-label={intl.formatMessage({ id: 'common.previous-page' })}
          />
          {pageGroups.map((group, idx) => {
            const numbers = group.map((pageNumber) => (
              <Button
                disabled={pageNumber === page}
                key={`invest-page-${pageNumber}`}
                variant={pageNumber === page ? undefined : 'ghost'}
                onPress={composeEventHandlers(() => setPage(pageNumber), onPagePressed)}
              >
                {pageNumber}
              </Button>
            ));

            if (idx >= 1 && group[0] - pageGroups[idx - 1][pageGroups[idx - 1].length - 1] > 1) {
              return (
                <Fragment key={`body-${idx}`}>
                  <BodyText>...</BodyText>
                  {numbers}
                </Fragment>
              );
            } else if (condensed) {
              return (
                <Fragment key={`body-${idx}`}>
                  {group[0] > 1 && <BodyText>...</BodyText>}
                  {numbers}
                  {group[group.length - 1] < totalPages && <BodyText>...</BodyText>}
                </Fragment>
              );
            }
            return numbers;
          })}
          <Button
            variant="ghost"
            disabled={!canNextPage()}
            Icon={ArrowRightIcon}
            onPress={composeEventHandlers(nextPage, onPagePressed)}
            aria-label={intl.formatMessage({ id: 'common.next-page' })}
          />
        </Stack>
      )}
    </>
  );
};
