import { useCallback, useMemo } from 'react';

import { useSessionStorage } from 'usehooks-ts';

import { InvestmentCategory } from '@arrived/models';

export const useShowCallout = (key?: InvestmentCategory): [boolean, (show: boolean) => void] => {
  const [investCalloutMap, setInvestCalloutMap] = useSessionStorage<Partial<Record<InvestmentCategory, boolean>>>(
    `invest-callout`,
    {},
  );

  const showCallout = useMemo(() => (key ? investCalloutMap[key] ?? true : false), [investCalloutMap, key]);
  const setShowCallout = useCallback(
    (show: boolean) => (key ? setInvestCalloutMap((map) => ({ ...map, [key]: show })) : {}),
    [key],
  );

  return useMemo(() => [showCallout, setShowCallout], [showCallout, setShowCallout]);
};
