import { FC, useEffect, useMemo } from 'react';

import { GetProps, createStyledContext, isWeb, styled, usePropsAndStyle, withStaticProperties } from '@tamagui/core';
import { LinearGradient } from '@tamagui/linear-gradient';

import { Image, ImageProps, Stack, StackProps, Tag, TitleText, UtilityText } from '../../atoms';
import { parseRootChildren } from '../../utils';

const PRODUCT_CARD_ADORNMENT_NAME = 'ProductCardAdornment';
const PRODUCT_CARD_CONTAINER_NAME = 'ProductCardContainer';
const PRODUCT_CARD_INNER_CONTAINER_NAME = 'ProductCardInnerContainer';
const PRODUCT_CARD_TITLE_NAME = 'ProductCardTitle';
const PRODUCT_CARD_TITLE_WRAPPER_NAME = 'ProductCardTitleWrapper';
const PRODUCT_CARD_TITLE_CONTENT_WRAPPER_NAME = 'ProductCardTitleContentWrapper';
const PRODUCT_CARD_DISPLAY_CONTAINER = 'ProductCardDisplayContainer';
const PRODUCT_CARD_DESCRIPTION_NAME = 'ProductCardDescription';
const PRODUCT_CARD_CONTENT_NAME = 'ProductCardContent';
const PRODUCT_CARD_IMAGE_NAME = 'ProductCardImage';
const PRODUCT_CARD_TAGS = 'ProductCardTags';
const PRODUCT_CARD_OVERLAY = 'ProductCardOverlay';

interface ProductCardStyledContextType {
  clickable?: boolean;
  condensed?: boolean;
  dimmed?: boolean;
}

const ProductCardStyledContext = createStyledContext<ProductCardStyledContextType>({
  clickable: false,
  condensed: false,
  dimmed: false,
});

const ProductCardContainer = styled(Stack, {
  name: PRODUCT_CARD_CONTAINER_NAME,
  context: ProductCardStyledContext,

  br: '$2',
  overflow: 'hidden',
  borderWidth: 0,
  p: 0,
  style: {
    textAlign: 'left',
  },

  // @ts-expect-error the tamagui types here need updating
  group: 'productCard',

  focusStyle: {
    outlineWidth: 0,
  },

  variants: {
    condensed: {
      true: {},
    },
    dimmed: {
      true: {},
    },
    clickable: {
      true: {
        cursor: 'pointer',
        tag: 'a',
      },
    },
  } as const,
});

const ProductCardInnerContainer = styled(Stack, {
  name: PRODUCT_CARD_INNER_CONTAINER_NAME,

  br: '$2',
  overflow: 'hidden',
  justifyContent: 'flex-end',
  p: 0,
  borderWidth: 0,
  flex: 1,
});

const ProductCardAdornment = styled(Stack, {
  name: PRODUCT_CARD_ADORNMENT_NAME,

  w: '100%',
  py: '$2',
  px: '$4',
});

const ProductCardOverlay = styled(Stack, {
  name: PRODUCT_CARD_OVERLAY,
  context: ProductCardStyledContext,

  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  bg: '$onSurface.neutral.tooltip',
  display: 'none',

  variants: {
    dimmed: {
      true: {
        display: 'flex',
      },
    },
  } as const,
});

/**
 * This surrounds the entire "upper" portion of the ProductCard's
 * content (i.e. the Title, Description and the gradient).
 */
const ProductCardTitleWrapper = styled(Stack, {
  name: PRODUCT_CARD_TITLE_WRAPPER_NAME,
  context: ProductCardStyledContext,

  px: '$6',
  pt: '$10',
  pb: '$4',
  zIndex: 1,

  variants: {
    condensed: {
      true: {
        px: '$4',
      },
    },
  } as const,
});

const ProductCardTitleContentWrapper = styled(Stack, {
  name: PRODUCT_CARD_TITLE_CONTENT_WRAPPER_NAME,
  context: ProductCardStyledContext,

  // TODO: Spec says 3, but it looks weird because the title has a
  // bigger line-height in reality than the spec
  gap: '$2',
  style: {
    textAlign: 'left',
  },

  variants: {
    condensed: {
      true: {
        gap: '$1',
      },
    },
  } as const,
});

const ProductCardTitle = styled(TitleText, {
  name: PRODUCT_CARD_TITLE_NAME,
  context: ProductCardStyledContext,

  token: 'title.heading.xlarge',
  color: '$onSurface.neutral.defaultInverted',
  // @ts-ignore -- Bricks doesn't recognize this as valid property
  'aria-level': 2,

  variants: {
    condensed: {
      true: {
        token: 'title.heading.medium',
      },
    },
  } as const,
});

const ProductCardDescription = withStaticProperties(
  styled(Stack, {
    name: PRODUCT_CARD_DESCRIPTION_NAME,

    row: true,
    gap: '$2',
    alignItems: 'center',
  }),
  {
    Text: styled(UtilityText, {
      token: 'utility.label.small.alt',
      color: '$onSurface.neutral.outline',
    }),
  },
);

const ProductCardContent = styled(Stack, {
  name: PRODUCT_CARD_CONTENT_NAME,
  context: ProductCardStyledContext,

  backgroundColor: '$onSurface.neutral.default',
  pb: '$4',
  px: '$6',

  variants: {
    condensed: {
      true: {
        px: '$4',
      },
    },
  } as const,
});

const ProductCardImageContainer = styled(Stack, {
  name: PRODUCT_CARD_IMAGE_NAME,
  context: ProductCardStyledContext,

  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  animation: 'quick',

  variants: {
    clickable: {
      true: {
        '$group-productCard-hover': {
          scale: 1.03,
        },
        '$group-productCard-focus': {
          scale: 1.03,
        },
        '$group-productCard-press': {
          // TODO: Not sure why, but this doesn't seem to be working on
          // mobile :(
          scale: isWeb ? 1 : 1.03,
        },
      },
    },
  } as const,
});

const ProductCardDisplay = styled(Stack, {
  name: PRODUCT_CARD_DISPLAY_CONTAINER,

  flex: 1,
  justifyContent: 'flex-end',
});

const ProductCardTags = styled(Stack, {
  name: PRODUCT_CARD_TAGS,

  position: 'absolute',
  top: '$4',
  right: '$4',
  flexDirection: 'row',
  gap: '$2',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
});

type ProductCardImageProps = StackProps & {
  source: string;
  ImageProps?: Omit<ImageProps, 'source'>;
  id?: number;
};

const ProductCardImage = ProductCardImageContainer.styleable<ProductCardImageProps>((props, ref) => {
  const [{ children, ImageProps, source, ...rest }, style] = usePropsAndStyle(props);

  return (
    <ProductCardImageContainer ref={ref} {...rest} {...style}>
      {children}
      <Image
        transition="quick"
        source={{ uri: source }}
        recyclingKey={source}
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        {...ImageProps}
      />
    </ProductCardImageContainer>
  );
});

const ProductCardComponent: FC<Omit<GetProps<typeof ProductCardContainer>, 'clickable'>> = (propsIn, ref) => {
  const [{ children: childrenIn, onPress, ...rest }, style] = usePropsAndStyle(propsIn);

  const {
    [PRODUCT_CARD_ADORNMENT_NAME]: Adornment,
    [PRODUCT_CARD_CONTENT_NAME]: Content,
    [PRODUCT_CARD_DESCRIPTION_NAME]: Description,
    [PRODUCT_CARD_IMAGE_NAME]: Image,
    [PRODUCT_CARD_TITLE_NAME]: Title,
    children,
  } = useMemo(
    () =>
      parseRootChildren(childrenIn, [
        PRODUCT_CARD_ADORNMENT_NAME,
        PRODUCT_CARD_CONTENT_NAME,
        PRODUCT_CARD_DESCRIPTION_NAME,
        PRODUCT_CARD_IMAGE_NAME,
        PRODUCT_CARD_TITLE_NAME,
      ]),
    [childrenIn],
  );

  useEffect(() => {
    if (!Image) {
      console.warn('ProductCard does not contain a ProductCard.Image component');
    }
  }, [Image]);

  useEffect(() => {
    if (!Title) {
      console.warn('ProductCard does not contain a ProductCard.Title component');
    }
  }, [Title]);

  return (
    <ProductCardContainer ref={ref} clickable={onPress != null} onPress={onPress} {...rest} {...style}>
      <ProductCardInnerContainer>
        <ProductCardDisplay>
          {Image}
          <ProductCardOverlay />
          <ProductCardTitleWrapper>
            <LinearGradient
              colors={['transparent', '$onSurface.neutral.default']}
              locations={[0, 0.5]}
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
            />
            {Boolean(Title || Description) && (
              <ProductCardTitleContentWrapper>
                {Title}
                {Description}
              </ProductCardTitleContentWrapper>
            )}
          </ProductCardTitleWrapper>
        </ProductCardDisplay>
        {Content}
        {children}
      </ProductCardInnerContainer>
      {Adornment}
    </ProductCardContainer>
  );
};

export const ProductCard = withStaticProperties(ProductCardContainer.styleable(ProductCardComponent), {
  Adornment: ProductCardAdornment,
  Content: ProductCardContent,
  Description: ProductCardDescription,
  Image: ProductCardImage,
  Tag,
  Tags: ProductCardTags,
  Title: ProductCardTitle,
});

export type ProductCardProps = GetProps<typeof ProductCard>;
