import { Stack, StackProps } from '@arrived/bricks';

import { Callout } from './callout';
import { EmptyState } from './EmptyState';
import { FilterSheet } from './filter';
import { Footer } from './Footer';
import { Grid } from './grid';
import { Header } from './Header';
import { Hooks } from './Hooks';
import { InvestCard } from './InvestCard';
import { InvestContextProvider, InvestContextProviderProps } from './InvestContext';
import { Loading } from './Loading';

export const Invest = ({
  children,
  page,
  setPage,
  ...rest
}: StackProps & Pick<InvestContextProviderProps, 'page' | 'setPage'>) => (
  <InvestContextProvider page={page} setPage={setPage}>
    <Stack data-e2e="invest-page-container" role="main" flex={1} {...rest}>
      {children}
      <FilterSheet />
    </Stack>
  </InvestContextProvider>
);

Invest.Callout = Callout;
Invest.Card = InvestCard;
Invest.Empty = EmptyState;
Invest.Footer = Footer;
Invest.Grid = Grid;
Invest.Header = Header;
Invest.Loading = Loading;
Invest.Hooks = Hooks;
