import { Loading as BricksLoading, LoadingProps as BricksLoadingProps, IndicatorProps } from '@arrived/bricks';

export type LoadingProps = BricksLoadingProps & {
  IndicatorProps?: IndicatorProps;
};

export const Loading = ({ IndicatorProps, ...rest }: LoadingProps) => (
  <BricksLoading height={300} display="flex" alignItems="center" justifyContent="center" {...rest}>
    <BricksLoading.Indicator variant="colored" {...IndicatorProps} />
  </BricksLoading>
);
