import { ComponentProps, PropsWithChildren } from 'react';

import { Divider, Stack, useMedia } from '@arrived/bricks';

import { Invest } from './Invest';
import { InvestContextProviderProps } from './InvestContext';

export type InvestImplProps = {
  Card: ComponentProps<typeof Invest.Grid>['Card'];
  InvestHeaderStyle?: ComponentProps<typeof Invest.Header>['style'];
} & Pick<InvestContextProviderProps, 'page' | 'setPage'>;

/**
 * This is the web implementation of the invest page, it exists to keep the public-app and web-app implementations in sync. Unlike the
 * components to build Invest, this one is very opinionated and offers very few options for customization.
 */
export const InvestImpl = ({ Card, InvestHeaderStyle, page, setPage }: InvestImplProps) => {
  const media = useMedia();

  return (
    <Invest
      bg="$onSurface.neutral.defaultInverted"
      width="100%"
      maxWidth={1440}
      $gtXxs={{ borderRadius: '$2' }}
      page={page}
      setPage={setPage}
    >
      <Invest.Header borderTopLeftRadius="$2" borderTopRightRadius="$2" zIndex="$sticky" style={InvestHeaderStyle} />
      <Divider solid alt />
      <Stack gap="$4" p="$4" flex={1}>
        <Invest.Callout />
        <Invest.Grid Card={Card} flex={1}>
          <Invest.Hooks>{Invest.Hooks.PreloadOfferingPhotos}</Invest.Hooks>
        </Invest.Grid>
      </Stack>
      <Invest.Footer
        borderBottomLeftRadius="$2"
        borderBottomRightRadius="$2"
        py="$4"
        px="$6"
        bg="$onSurface.neutral.defaultInverted"
        onPagePressed={() => window.scrollTo({ top: 0 })}
        zIndex="$sticky"
        condensed={!media.gtXs}
        style={{
          position: 'sticky',
          bottom: 0,
        }}
      />
    </Invest>
  );
};

const InvestImplContainer = ({ children }: PropsWithChildren) => (
  <Stack centered $gtXxs={{ px: '$4' }} bg="$surface.neutral.default" flex={1}>
    {children}
  </Stack>
);

InvestImpl.Container = InvestImplContainer;
