import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function PauseInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 22 22" {...rest}>
      <Path d="M1 11a10 10 0 1 0 20 0 10 10 0 0 0-20 0Zm7.5-5v10m5-10v10" stroke={color} strokeWidth={1.25} />
    </Svg>
  );
}

export const PauseIcon = memo<IconProps>(withIconTheme(PauseInternal));
