import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function PadlockInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 20 20" {...rest}>
      <Path
        stroke={color}
        strokeWidth={1.25}
        d="M17.5 9.167h-15v10h15v-10ZM10 12.5v3.333M5.833 9.167V5a4.167 4.167 0 0 1 8.334 0"
      />
    </Svg>
  );
}

export const PadlockIcon = memo<IconProps>(withIconTheme(PadlockInternal));
