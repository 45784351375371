import { memo } from 'react';

import { ClipPath, Defs, G, Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function CoinsIconInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 24 24" {...rest}>
      <G clipPath="url(#prefix__clip0_314_254)" stroke={color} strokeWidth={1.5}>
        <Path d="M3.18 9.815A8 8 0 0014.39 20.69M14 18a8 8 0 100-16 8 8 0 000 16zM14 7V5M14 15v-2" />
        <Path d="M16.25 8.5V8a1 1 0 00-1-1h-2.5a1 1 0 00-1 1v.448a1 1 0 00.783.976l2.934.652a1 1 0 01.783.976V12a1 1 0 01-1 1h-2.5a1 1 0 01-1-1v-.5" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0_314_254">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export const CoinsIcon = memo<IconProps>(withIconTheme(CoinsIconInternal));
