import { FormattedMessage } from 'react-intl';

import {
  EmptyState as BricksEmptyState,
  EmptyStateProps as BricksEmptyStateProps,
  Button,
  Image,
  TitleText,
} from '@arrived/bricks';
import { ikClient } from '@arrived/imagekit';

const magnifyingGlassUri = ikClient.url({
  path: '/bricks/invest/invest.no.properties.png',
  transformation: [{ width: '280', height: '280' }],
});

/**
 * Can be extended to take other properties from the Invest component internally and expose them to external code.
 */
export interface EmptyStateProps extends BricksEmptyStateProps {
  openFilterSheet: () => void;
}

export const EmptyState = ({ openFilterSheet, ...rest }: EmptyStateProps) => {
  return (
    <BricksEmptyState {...rest}>
      <Image source={{ uri: magnifyingGlassUri }} alt="Magnifying Glass" width={140} height={140} />
      <TitleText token="title.heading.medium">
        <FormattedMessage id="invest-page.empty-state.title" />
      </TitleText>
      <BricksEmptyState.Text>
        <FormattedMessage id="invest-page.empty-state.description" />
      </BricksEmptyState.Text>
      <Button onPress={openFilterSheet} width="100%">
        <FormattedMessage id="invest-page.empty-state.cta" />
      </Button>
    </BricksEmptyState>
  );
};
