import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { useDebounce } from 'usehooks-ts';

import { UsePaginationReturn, getNextPage, getPreviousPage, getTotalPages, useDisclosure } from '@arrived/bricks';
import { createUseContext, useOfferingFilters } from '@arrived/contexts';
import { OfferingFilter, convertOfferingFilterToSearchQuery } from '@arrived/models';
import { useOfferingSearchQuery } from '@arrived/queries';

export interface InvestContextType {
  paginationProps: UsePaginationReturn;
  useDisclosureProps: ReturnType<typeof useDisclosure>;
}

export const useInvestContextOfferingSearchQuery = ({
  offeringFilter,
  paginationProps,
}: {
  offeringFilter: OfferingFilter;
  paginationProps: UsePaginationReturn;
}) => {
  const offeringSearchQuery = useMemo(
    () => ({
      ...convertOfferingFilterToSearchQuery(offeringFilter),
      page: paginationProps.page,
      sort: 'propertyRank:desc,id:desc',
      size: paginationProps.itemsPerPage,
    }),
    [offeringFilter, paginationProps.page, paginationProps.itemsPerPage],
  );

  return useOfferingSearchQuery(offeringSearchQuery);
};

export const useDisplayEmptyState = (offeringState: ReturnType<typeof useOfferingSearchQuery>) => {
  const displayLoading = useDisplayLoading(offeringState);

  return useMemo(
    () => !displayLoading && offeringState.isSuccess && offeringState.data?.pagination?.totalResults === 0,
    [displayLoading, offeringState.isSuccess, offeringState.data?.pagination?.totalResults],
  );
};

export const useDisplayLoading = (offeringState: ReturnType<typeof useOfferingSearchQuery>) => {
  const debouncedIsFetching = useDebounce(offeringState.isFetching, 250);

  // Need to do offeringState.isFetching && debouncedIsFetching to avoid adding a lag between when the fetch stops. This way the only case in which
  // this is true is when offeringState.isFetching is true (meaning we are actively fetching), and debouncedIsFetching is true (meaning we've waited enough
  // to start showing the loading indicator). As soon as isFetching is false then this evaluates to false even if the debounced value is still true.
  return useMemo(() => offeringState.isPending && debouncedIsFetching, [debouncedIsFetching, offeringState.isFetching]);
};

export type InvestContextProviderProps = PropsWithChildren<{
  page: number;
  setPage: (page: number) => void;
}>;

export const [InvestContextProvider, useInvestContext, InvestContext] = createUseContext<
  InvestContextType,
  InvestContextProviderProps
>(
  {
    paginationProps: {} as UsePaginationReturn,
    useDisclosureProps: {} as InvestContextType['useDisclosureProps'],
  },
  (Provider) =>
    ({ children, page, setPage }) => {
      const useDisclosureProps = useDisclosure();

      const { offeringFilter } = useOfferingFilters();

      const [totalItems, setTotalItems] = useState(0);
      const [itemsPerPage, setItemsPerPage] = useState(24);

      const totalPages = useMemo(() => getTotalPages({ itemsPerPage, totalItems }), [totalItems]);

      useEffect(() => {
        if (page < 1) {
          setPage(1);
        } else if (totalPages > 0 && page > totalPages) {
          setPage(totalPages);
        }
      }, [page, totalPages]);

      const canNextPage = useCallback(() => (page ?? 1) < totalPages, [page, totalPages]);
      const canPreviousPage = useCallback(() => (page ?? 1) > 1, [page]);

      const nextPage = useCallback(
        () => setPage(getNextPage({ page: page ?? 1, totalPages })),
        [page, setPage, totalPages],
      );
      const previousPage = useCallback(() => setPage(getPreviousPage({ page: page ?? 1 })), [page, setPage]);

      const paginationProps = useMemo(
        () => ({
          canNextPage,
          canPreviousPage,
          itemsPerPage,
          nextPage,
          page: page ?? 1,
          previousPage,
          setItemsPerPage,
          setPage,
          totalItems,
          totalPages,
        }),
        [
          canNextPage,
          canPreviousPage,
          itemsPerPage,
          nextPage,
          page,
          previousPage,
          setItemsPerPage,
          setPage,
          totalItems,
          totalPages,
        ],
      );

      const offeringState = useInvestContextOfferingSearchQuery({
        offeringFilter,
        paginationProps,
      });

      useEffect(() => {
        if (offeringState.data?.pagination?.totalResults != null) {
          setTotalItems(offeringState.data?.pagination?.totalResults);
        }
      }, [offeringState.data?.pagination?.totalResults]);

      const value = useMemo(
        () => ({
          paginationProps,
          useDisclosureProps,
        }),
        [paginationProps, useDisclosureProps],
      );

      return <Provider value={value}>{children}</Provider>;
    },
);
