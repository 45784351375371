import { useCallback, useEffect, useMemo, useState } from 'react';

interface UsePaginationArguments {
  itemsPerPage: number;
  page: number;
  totalItems: number;
}

export const getTotalPages = ({ itemsPerPage, totalItems }: { itemsPerPage: number; totalItems: number }) =>
  itemsPerPage ? Math.ceil(totalItems / itemsPerPage) : 0;

export const getPreviousPage = ({ page }: { page: number }) => Math.max(page - 1, 0);

export const getNextPage = ({ page, totalPages }: { page: number; totalPages: number }) =>
  Math.min(page + 1, totalPages);

export function usePagination({ totalItems, ...props }: UsePaginationArguments) {
  const [itemsPerPage, setItemsPerPage] = useState(Math.max(props.itemsPerPage, 1));
  const [page, setPage] = useState(Math.max(props.page, 1));

  useEffect(() => setItemsPerPage(Math.max(props.itemsPerPage, 1)), [props.itemsPerPage]);
  useEffect(() => setPage(Math.max(props.page, 1)), [props.page]);

  const totalPages = useMemo(() => getTotalPages({ itemsPerPage, totalItems }), [itemsPerPage, totalItems]);

  const canNextPage = useCallback(() => page < totalPages, [page, totalPages]);
  const canPreviousPage = useCallback(() => page > 1, [page]);

  const nextPage = useCallback(() => setPage((page) => getNextPage({ page, totalPages })), [setPage, totalPages]);
  const previousPage = useCallback(() => setPage((currentPage) => getPreviousPage({ page: currentPage })), [setPage]);

  return useMemo(
    () => ({
      canNextPage,
      canPreviousPage,
      itemsPerPage,
      nextPage,
      page,
      previousPage,
      setItemsPerPage,
      setPage,
      totalItems,
      totalPages,
    }),
    [canNextPage, canPreviousPage, itemsPerPage, setItemsPerPage, nextPage, page, previousPage, setPage, totalItems],
  );
}

export type UsePaginationReturn = ReturnType<typeof usePagination>;
