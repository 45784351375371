import { FC, useMemo } from 'react';

import { FormattedMessage, FormattedNumber, FormattedPlural } from 'react-intl';

import { differenceInMonths } from 'date-fns';
import _uniq from 'lodash/uniq';

import { DataPoint, DataPointProps } from '@arrived/bricks';
import { OfferingTransactState, getActiveLoansCount, isOfferingSoldOut } from '@arrived/common';
import { useInvestmentProductType, useOfferingTransactState } from '@arrived/hooks';
import { InvestmentProductType, Offering, OfferingId } from '@arrived/models';
import { useIsOfferingForSale, useOfferingQuery } from '@arrived/queries';

function useShowInvestorCount(offeringId: OfferingId) {
  const isOfferingForSale = useIsOfferingForSale();
  const offeringState = useOfferingQuery(offeringId);

  return offeringState.isSuccess && (isOfferingForSale(offeringState.data) || isOfferingSoldOut(offeringState.data));
}

const StyledDataPoint = (props: DataPointProps<true>) => (
  <DataPoint variant="minimized" inline flexShrink={1} {...props} />
);

const Location = ({ offering }: { offering: Offering }) => (
  <StyledDataPoint>
    <DataPoint.Label color="$onSurface.neutral.defaultInverted">{offering.market?.title}</DataPoint.Label>
  </StyledDataPoint>
);

const InvestorCount = ({ offering }: { offering: Offering }) => (
  <StyledDataPoint>
    <DataPoint.Value color="$onSurface.neutral.defaultInverted">
      <FormattedNumber compactDisplay="short" notation="compact" value={offering.investorsCount} />
    </DataPoint.Value>
    <DataPoint.Label color="$onSurface.neutral.defaultInverted">
      <FormattedMessage id="common.investors" />
    </DataPoint.Label>
  </StyledDataPoint>
);

const MarketCount = ({ offering }: { offering: Offering }) => {
  const marketCount = useMemo(
    () => _uniq(offering.properties.map(({ marketId }) => marketId).filter((marketId) => marketId != null)).length,
    [offering.properties],
  );

  return (
    <StyledDataPoint>
      <DataPoint.Value color="$onSurface.neutral.defaultInverted">
        <FormattedNumber compactDisplay="short" notation="compact" value={marketCount} />
      </DataPoint.Value>
      <DataPoint.Label color="$onSurface.neutral.defaultInverted">
        <FormattedMessage id="invest.markets" />
      </DataPoint.Label>
    </StyledDataPoint>
  );
};

const PropertyCount = ({ offering }: { offering: Offering }) => (
  <StyledDataPoint>
    <DataPoint.Value color="$onSurface.neutral.defaultInverted">
      <FormattedNumber compactDisplay="short" notation="compact" value={offering.properties.length} />
    </DataPoint.Value>
    <DataPoint.Label color="$onSurface.neutral.defaultInverted">
      <FormattedMessage id="invest.properties" />
    </DataPoint.Label>
  </StyledDataPoint>
);

const AvailableAmount = ({ offering }: { offering: Offering }) => (
  <StyledDataPoint>
    <DataPoint.Value color="$onSurface.neutral.defaultInverted">
      <FormattedNumber
        compactDisplay="short"
        notation="compact"
        style="currency"
        currency="USD"
        value={offering.targetRaiseAmount}
      />
    </DataPoint.Value>
    <DataPoint.Label color="$onSurface.neutral.defaultInverted">
      <FormattedMessage id="invest.available" />
    </DataPoint.Label>
  </StyledDataPoint>
);

const TargetRaiseAmount = ({ offering }: { offering: Offering }) => (
  <StyledDataPoint>
    <DataPoint.Value color="$onSurface.neutral.defaultInverted">
      <FormattedNumber
        compactDisplay="short"
        notation="compact"
        style="currency"
        currency="USD"
        value={offering.targetRaiseAmount}
      />
    </DataPoint.Value>
  </StyledDataPoint>
);

const AnnualizedYield = ({ offering }: { offering: Offering }) => (
  <StyledDataPoint>
    <DataPoint.Value color="$onSurface.neutral.defaultInverted">
      <FormattedNumber
        style="percent"
        maximumFractionDigits={3}
        value={offering.fixedRateInvestments?.[0]?.yieldPercent ?? 0}
      />
    </DataPoint.Value>
    <DataPoint.Label color="$onSurface.neutral.defaultInverted">
      <FormattedMessage id="invest.yield" />
    </DataPoint.Label>
  </StyledDataPoint>
);

const LoanCount = ({ offering }: { offering: Offering }) => {
  const loanCount = useMemo(() => getActiveLoansCount(offering), [offering]);
  if (!offering.fixedRateInvestments) return null;

  return (
    <StyledDataPoint>
      <DataPoint.Value color="$onSurface.neutral.defaultInverted">
        <FormattedNumber compactDisplay="short" notation="compact" value={loanCount} />
      </DataPoint.Value>
      <DataPoint.Label color="$onSurface.neutral.defaultInverted">
        <FormattedMessage id="invest.units.loans" />
      </DataPoint.Label>
    </StyledDataPoint>
  );
};

const Months = ({ offering }: { offering: Offering }) => {
  const firstValue = offering.fixedRateInvestments?.[0];

  if (!firstValue) {
    return null;
  }

  const matureAt = new Date(firstValue.matureAt);
  const interestAccrualStartAt = new Date(firstValue.interestAccrualStartAt);

  const noteDuration = differenceInMonths(matureAt, interestAccrualStartAt);

  return (
    <StyledDataPoint>
      <DataPoint.Value color="$onSurface.neutral.defaultInverted">{noteDuration}</DataPoint.Value>
      <DataPoint.Label color="$onSurface.neutral.defaultInverted">
        <FormattedPlural value={noteDuration} one="Month" other="Months" />
      </DataPoint.Label>
    </StyledDataPoint>
  );
};

export function useInvestCardContent(offering: Offering) {
  const showInvestorCount = useShowInvestorCount(offering.id);
  const offeringTransactState = useOfferingTransactState(offering);

  const investmentProductType = useInvestmentProductType(offering);

  return useMemo(() => {
    const base = [] as FC<{ offering: Offering }>[];

    switch (investmentProductType) {
      case InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_FUND:
        base.push(MarketCount, PropertyCount);
        break;
      case InvestmentProductType.VACATION_RENTAL_IPO:
      case InvestmentProductType.SINGLE_FAMILY_RESIDENTIAL_IPO:
        if (offering.market?.title) {
          base.push(Location);
        }
        if (offeringTransactState === OfferingTransactState.PRE) {
          base.push(AvailableAmount);
        }
        break;
      case InvestmentProductType.SHORT_TERM_NOTE:
        base.push(AnnualizedYield, Months, TargetRaiseAmount);
        break;
      case InvestmentProductType.PRIVATE_CREDIT_FUND:
        if (offering.fixedRateInvestments && offering.fixedRateInvestments?.length > 0) {
          base.push(LoanCount);
        }
        break;
    }

    if (showInvestorCount && investmentProductType !== InvestmentProductType.SHORT_TERM_NOTE) {
      base.push(InvestorCount);
    }

    return base;
  }, [investmentProductType, offering.market?.title, offeringTransactState, showInvestorCount]);
}
